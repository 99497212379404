<template>
  <f7-page
    class="main-loginPage"
    login-screen
    style="background: url('https://www.hispatectrack.com/wp-content/uploads/slider.jpg') no-repeat center center; background-size: cover;"
  >
    <ResetPassword
      :has-restore-pass="true"
    />
  </f7-page>
</template>

<script>
import ResetPassword from '../../components/resetPassword/index.vue';

export default {
  name: 'ResetPasswordPage',
  components: {
    ResetPassword,
  },
  data() {
    return {
      ResetPassword,
    };
  },
};
</script>
