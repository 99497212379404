<template>
  <f7-page
    class="main-loginPage"
    login-screen
    style="background: url('https://www.hispatectrack.com/wp-content/uploads/slider.jpg') no-repeat center center; background-size: cover;"
  >
    <ResetPasswordConfirm
      :token="getToken"
    />
  </f7-page>
</template>

<script>
import ResetPasswordConfirm from '../../../components/resetPassword/resetConfirm/index.vue';

export default {
  name: 'ResetPasswordPage',
  components: {
    ResetPasswordConfirm,
  },
  data() {
    return {
      ResetPasswordConfirm,
    };
  },
  computed: {
    getToken() {
      let token = '';
      const uri = window.location.href.split('?');
      if (uri.length === 2) {
        const vars = uri[1].split('&');
        const getVars = {};
        let tmp = '';
        vars.forEach((v) => {
          tmp = v.split('=');
          if (tmp.length === 2) { getVars[tmp[0]] = tmp[1]; }
        });
        // do
        token = getVars.token;
      }
      return token;
    },
  },
};
</script>
