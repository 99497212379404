import Request from './Request';
import EventBus from '../js/event-bus';
import { BASE_URL } from '../js/constants';

let token = '';
let headerAuth = {};
let currentCompanyId = '';

const baseUrl = BASE_URL;

EventBus.$on('newLoggedUser', ({ payload, companyId }) => {
  const data = JSON.parse(payload);
  token = data.token;
  headerAuth = `Bearer ${token}`;
  currentCompanyId = companyId || data.contact.company_id;
});

EventBus.$on('changeCurrentCompanyId', (newCompanyId) => {
  currentCompanyId = newCompanyId;
});

export default {
  errors: {
    default: {
      username: 'error',
      message: 'error',
    },
    E002: {
      username: 'invalid_token',
      message: 'Su sesión ha expirado',
    },
  },
  getToken() {
    return token;
  },
  getCompanyHeader() {
    return currentCompanyId;
  },
  getHeaderAuth() {
    return headerAuth;
  },
  getBaseUrl() {
    return baseUrl;
  },
  // login
  login(credentials) {
    const url = `${baseUrl}/user/authorization`;
    const payload = {
      data: JSON.stringify({
        username: credentials.username,
        password: credentials.password,
      }),
    };
    return Request.async(url, payload, 'POST', 'application/json', {}).then(
      this.handleLoginResult
    );
  },
  // Reset password
  resetPassword: {
    url: `${baseUrl}/user/recovery`,
    payload: null,
    async reset(passwordReser) {
      return Request.async(
        this.url,
        JSON.stringify(passwordReser),
        'POST',

        'application/json',
        {
          headerAuth,
          headerCompany: currentCompanyId,
        },
        null
      );
    },
    async resetConfirm(passwordToken) {
      return Request.async(
        `${this.url}/confirm`,
        JSON.stringify(passwordToken),
        'POST',

        'application/json',
        {
          headerAuth,
          headerCompany: currentCompanyId,
        },
        null
      );
    },
  },
  async handleLoginResult(result) {
    if (result.response !== '') {
      EventBus.$emit('newLoggedUser', { payload: result.response });
    }
  },
  getElementById(module, id) {
    const url = `${baseUrl}/${module}/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', 'application/json', {
      headerAuth,
      headerCompany: currentCompanyId,
    });
  },
  createElement(module, newElement) {
    const url = `${baseUrl}/${module}`;
    const payload = {
      data: JSON.stringify(newElement),
    };
    return Request.async(url, payload, 'POST', 'application/json', {
      headerAuth,
      headerCompany: currentCompanyId,
    });
  },
  updateElement(module, element) {
    const url = `${baseUrl}/${module}/${element.id}`;
    const payload = {
      data: JSON.stringify(element),
    };
    return Request.async(url, payload, 'PUT', 'application/json', {
      headerAuth,
      headerCompany: currentCompanyId,
    });
  },
  deleteElement(module, id) {
    const url = `${baseUrl}/${module}/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', 'application/json', {
      headerAuth,
      headerCompany: currentCompanyId,
    });
  },
  getElements(
    module,
    {
      ordering,
      code,
      external_code,
      name,
      status,
      take,
      skip,
      filter,
      sort,
      group,
      family,
      id,
    }
  ) {
    let url = `${baseUrl}/${module}`;
    url += `?${this.getQueryParams({
      ordering,
      code,
      external_code,
      name,
      status,
      take,
      skip,
      filter,
      sort,
      group,
      family,
      id,
    })}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', 'application/json', {
      headerAuth,
      headerCompany: currentCompanyId,
    });
  },
  getElementsByQueryParams(module, queryParams, enabledCache = false) {
    let url = `${baseUrl}/${module}`;
    const queryToken = {};
    if (enabledCache) {
      queryToken.token = token;
    }
    url += `?${this.getQueryParams({ ...queryParams, ...queryToken })}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      'application/json',
      {
        headerAuth,
        headerCompany: currentCompanyId,
      },
      enabledCache
    );
  },
  getQueryParams(params) {
    const queryParams = new URLSearchParams();
    for (const param in params) {
      if (Object.hasOwnProperty.call(params, param)) {
        const value = params[param];
        if (typeof value !== 'undefined') {
          queryParams.set(param, value);
        }
      }
    }
    return queryParams.toString();
  },
};
