// Import Vue & Framework7
import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle';

// Styles
import 'framework7/css/framework7.bundle.css';
import '../css/icons.css';
import '../css/uicons-regular-rounded.css';

// Import DevExtreme
import 'devextreme/dist/css/dx.light.css';
import '../themes/light/dx.material.custom-scheme.css';

import * as overlay from 'devextreme/ui/overlay';

// Styles Hispatec
import '../css/app.scss';

// Import App Component
import VCalendar from 'v-calendar';
import i18n from './i18n.config';
import App from '../components/app.vue';
import store from '../store/index';

// Importamos librerías de apoyo
import Api from '../services/Api';
import Helpers from './helpers';
import Emitter from './event-bus';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Inicializamos las librerías de apoyo
Vue.use(VCalendar);

overlay.baseZIndex(9999);

// Init App
Vue.prototype.$helpers = Helpers;
Vue.prototype.$api = Api;
Vue.prototype.$emitter = Emitter;
try {
  // eslint-disable-next-line no-new
  new Vue({
    el: '#app',
    // Register App Component
    components: {
      app: App,
    },
    render: (h) => h(App),
    i18n,
    store,
  });
} catch (error) {
  // eslint-disable-next-line no-console
  console.log(error);
}
