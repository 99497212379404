import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { DICTIONARIES, DEFAULT_LANGUAGE } from './constants';

Vue.use(VueI18n);

const whitelist = [];
for (const languageId in DICTIONARIES) {
  if (Object.hasOwnProperty.call(DICTIONARIES, languageId)) {
    whitelist.push(languageId);
  }
}
const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: 'en-US',
  whitelist,
  messages: DICTIONARIES,
});

export default i18n;
