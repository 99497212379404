const routes = [
  // -- DEFAULT --
  {
    path: '/',
    asyncComponent: () => import('../pages/monitoring/monitoringPage.vue'),
  },
  // -- ACCOUNTS --
  {
    path: '/accounts/loginPage/',
    asyncComponent: () => import('../pages/accounts/loginPage.vue'),
  },
  {
    path: '/accounts/logout/',
    asyncComponent: () => import('../pages/accounts/logout.vue'),
  },
  {
    path: '/profilePage',
    asyncComponent: () => import('../pages/accounts/profilePage.vue'),
  },
  // analytics
  {
    path: '/resetPassword/',
    asyncComponent: () => import('../pages/resetPassword/resetPassword.vue'),
  },
  {
    path: 'resetPasswordConfirm',
    asyncComponent: () =>
      import('../pages/resetPassword/resetConfirm/resetConfirm.vue'),
  },
  // -- MONITORING --
  {
    path: '/monitoringPage',
    asyncComponent: () => import('../pages/monitoring/monitoringPage.vue'),
  },
  {
    path: '/CRUDMonitoring/:shipmentId/:orderId/:actionCRUD',
    asyncComponent: () => import('../pages/monitoring/CRUDMonitoring.vue'),
  },
  // -- ORDER --
  {
    path: '/orderPage',
    asyncComponent: () => import('../pages/order/orderPage.vue'),
  },
  {
    path: '/CRUDOrder/:elementId/:actionCRUD',
    asyncComponent: () => import('../pages/order/CRUDOrder.vue'),
  },
  // -- SHIPMENT --
  {
    path: '/shipmentPage',
    asyncComponent: () => import('../pages/shipment/shipmentPage.vue'),
  },
  {
    path: '/CRUDShipment/:elementId/:actionCRUD',
    asyncComponent: () => import('../pages/shipment/CRUDShipment.vue'),
  },
  {
    path: '/shipmentReport/:elementId',
    asyncComponent: () => import('../pages/shipment/shipmentReport.vue'),
  },
  // -- CLAIM --
  {
    path: '/claimPage',
    asyncComponent: () => import('../pages/claims/claimPage.vue'),
  },
  {
    path: '/CRUDClaim/:elementId/:actionCRUD',
    asyncComponent: () => import('../pages/claims/CRUDClaim.vue'),
  },
  // -- POWER BI --
  {
    path: '/powerBiEmbedPage',
    asyncComponent: () => import('../pages/powerBi/powerBiEmbedPage.vue'),
  },
  // -- MANAGEMENT --
  // PRODUCT
  {
    path: '/productPage',
    asyncComponent: () => import('../pages/management/product/productPage.vue'),
  },
  {
    path: '/CRUDProduct/:elementId/:actionCRUD',
    asyncComponent: () => import('../pages/management/product/CRUDProduct.vue'),
  },
  // PROTOCOL
  {
    path: '/protocolPage',
    asyncComponent: () =>
      import('../pages/management/protocol/protocolPage.vue'),
  },
  {
    path: '/CRUDProtocol/:elementId/:actionCRUD',
    asyncComponent: () =>
      import('../pages/management/protocol/CRUDProtocol.vue'),
  },
  // ENTITY
  {
    path: '/entityPage',
    asyncComponent: () => import('../pages/management/entity/entityPage.vue'),
  },
  {
    path: '/CRUDEntity/:elementId/:actionCRUD',
    asyncComponent: () => import('../pages/management/entity/CRUDEntity.vue'),
  },
  {
    path: '/entityLocationPage/:elementId',
    asyncComponent: () =>
      import('../pages/management/entity/location/entityLocationPage.vue'),
  },
  {
    path: '/CRUDEntityLocation/:elementId/:locationId/:actionCRUD',
    asyncComponent: () =>
      import('../pages/management/entity/location/CRUDEntityLocation.vue'),
  },
  // COMPANY GROUP
  {
    path: '/groupPage',
    asyncComponent: () => import('../pages/management/group/groupPage.vue'),
  },
  {
    path: '/CRUDGroup/:elementId/:actionCRUD',
    asyncComponent: () => import('../pages/management/group/CRUDGroup.vue'),
  },
  // -- ADMINISTRATION --
  // CONTACT
  {
    path: '/contactPage',
    asyncComponent: () =>
      import('../pages/administration/contact/contactPage.vue'),
  },
  {
    path: '/CRUDContact/:elementId/:actionCRUD',
    asyncComponent: () =>
      import('../pages/administration/contact/CRUDContact.vue'),
  },
  {
    path: '/companyPage',
    asyncComponent: () =>
      import('../pages/administration/company/companyPage.vue'),
  },
  {
    path: '/CRUDCompany/:elementId/:actionCRUD',
    asyncComponent: () =>
      import('../pages/administration/company/CRUDCompany.vue'),
  },
  // -- NOT FOUND --
  {
    path: '(.*)',
    asyncComponent: () => import('../pages/404.vue'),
  },
];

export default routes;
