<!--LEFT PANEL -->
<template>
  <div id="sidemenu" class="menu-collapsed" ref="sidemenu">
    <!-- Header -->
    <div id="header">
      <div id="title">
        <span />
      </div>
      <div id="menu-btn" @click="menuCollapse()">
        <div class="btn-hamburger" />
        <div class="btn-hamburger" />
        <div class="btn-hamburger" />
      </div>
    </div>

    <div id="profile">
      <div id="photo">
        <img :src="'../static/img/logoHispatec.png'" alt="comnpany" />
      </div>
      <div id="name">
        <span>{{ user.contact.company_name }} </span>
      </div>
    </div>
    <!--Items-->
    <div id="menu-items">
      <!--Item-->
      <div
        v-if="user.contact.tools.DEVICES.VIEW"
        class="item"
        :title="$t('leftPanel.title.monitoring')"
        :class="{ 'selected-menu-item': selectedItem === 'monitoring' }"
      >
        <a href="#" @click="goMonitoring()">
          <div class="icon">
            <em><span class="fi-rr-sensor-on" /></em>
          </div>
          <div class="title">
            <span>{{ $t('leftPanel.title.monitoring') }}</span>
          </div>
        </a>
      </div>

      <!--Item-->
      <div
        v-if="user.contact.tools.ORDERS.VIEW"
        class="item"
        :title="$t('leftPanel.title.orders')"
        :class="{ 'selected-menu-item': selectedItem === 'order' }"
      >
        <a href="#" @click="goOrder()">
          <div class="icon">
            <em><span class="fi fi-rr-box-alt" /></em>
          </div>
          <div class="title">
            <span>{{ $t('leftPanel.title.orders') }}</span>
          </div>
        </a>
      </div>

      <!--Item-->
      <div
        v-if="user.contact.tools.SHIPMENTS.VIEW"
        class="item"
        :title="$t('leftPanel.title.trips')"
        :class="{ 'selected-menu-item': selectedItem === 'shipment' }"
      >
        <a href="#" @click="goShipment()">
          <div class="icon">
            <em><span class="fi fi-rr-plane" /></em>
          </div>
          <div class="title">
            <span>{{ $t('leftPanel.title.trips') }}</span>
          </div>
        </a>
      </div>

      <!--Item -->
      <div
        v-if="user.contact.tools.CLAIMS.VIEW"
        class="item"
        :title="$t('leftPanel.title.claims')"
        :class="{ 'selected-menu-item': selectedItem === 'claims' }"
      >
        <a href="#" @click="goClaims()">
          <div class="icon">
            <em><span class="fi fi-rr-exclamation" /></em>
          </div>
          <div class="title">
            <span>{{ $t('leftPanel.title.claims') }}</span>
          </div>
        </a>
      </div>
      <!--Item -->
      <div
        v-if="user.contact.tools.ANALYTICS.VIEW"
        class="item"
        :title="$t('leftPanel.title.powerBi')"
        :class="{ 'selected-menu-item': selectedItem === 'powerBi' }"
      >
        <a href="#" @click="goPowerBi()">
          <div class="icon">
            <em><span class="fi-rr-chart-pie" /></em>
          </div>
          <div class="title">
            <span>{{ $t('leftPanel.title.powerBi') }}</span>
          </div>
        </a>
      </div>
      <!--Item DESPLEGABLE-->
      <div class="item" :title="$t('leftPanel.title.management')">
        <a href="#" @click="goManagement()">
          <div class="icon" :class="{ 'expanded-menu': !arrowdown }">
            <em>
              <span class="fi fi-rr-settings" />
              <span v-if="gestion" class="fi fi-rr-caret-up" />
              <span v-else class="fi fi-rr-caret-down" />
            </em>
          </div>
          <div class="title">
            <span v-if="!gestion">
              <em><span class="fi fi-rr-caret-down" /></em>
            </span>
            <span v-else>
              <em><span class="fi fi-rr-caret-up" /></em>
            </span>
            <span>{{ $t('leftPanel.title.management') }}</span>
          </div>
        </a>
      </div>
      <!--elemento del desplegable-->
      <div v-if="gestion">
        <div
          v-if="user.contact.tools.PRODUCTS.VIEW"
          class="item submenu-item"
          :title="$t('leftPanel.title.products')"
          :class="{ 'selected-menu-item': selectedItem === 'products' }"
        >
          <a href="#" @click="goProducts()">
            <div class="icon">
              <em><span class="fi fi-rr-carrot" /></em>
            </div>
            <div class="title">
              <span>{{ $t('leftPanel.title.products') }}</span>
            </div>
          </a>
        </div>

        <!--elemento del desplegable-->
        <div
          v-if="user.contact.tools.DOCUMENTS_PROTOCOL.VIEW"
          class="item submenu-item"
          :title="$t('leftPanel.title.protocols')"
          :class="{ 'selected-menu-item': selectedItem === 'protocolsNew' }"
        >
          <a href="#" @click="goProtocols()">
            <div class="icon">
              <em><span class="fi fi-rr-ftp" /></em>
            </div>
            <div class="title">
              <span>{{ $t('leftPanel.title.protocols') }}</span>
            </div>
          </a>
        </div>

        <!--elemento del desplegable-->
        <div
          v-if="user.contact.tools.ENTITIES.VIEW"
          class="item submenu-item"
          :title="$t('leftPanel.title.entities')"
          :class="{ 'selected-menu-item': selectedItem === 'entities' }"
        >
          <a href="#" @click="goEntities()">
            <div class="icon">
              <em><span class="fi fi-rr-bank" /></em>
            </div>
            <div class="title">
              <span>{{ $t('leftPanel.title.entities') }}</span>
            </div>
          </a>
        </div>

        <!--elemento del desplegable-->
        <div
          v-if="false"
          class="item submenu-item"
          :title="$t('leftPanel.title.documents')"
          :class="{ 'selected-menu-item': selectedItem === 'documents' }"
        >
          <a href="#" @click="goDocuments()">
            <div class="icon">
              <em><span class="fi fi-rr-document" /></em>
            </div>
            <div class="title">
              <span>{{ $t('leftPanel.title.documents') }}</span>
            </div>
          </a>
        </div>
        <!--elemento del desplegable 2-->
        <div
          v-if="user.contact.tools.GROUPS.VIEW"
          class="item submenu-item"
          :title="$t('leftPanel.title.groups')"
          :class="{ 'selected-menu-item': selectedItem === 'groups' }"
        >
          <a href="#" @click="goGroups()">
            <div class="icon">
              <em><span class="fi fi-rr-users" /></em>
            </div>
            <div class="title">
              <span>{{ $t('leftPanel.title.groups') }}</span>
            </div>
          </a>
        </div>
      </div>

      <!--Item DESPLEGABLE 2-->
      <div class="item" :title="$t('leftPanel.title.administration')">
        <a href="#" @click="goAdministration()">
          <div class="icon" :class="{ 'expanded-menu': !arrowdown }">
            <em>
              <span class="fi fi-rr-calendar" />
              <span v-if="administration" class="fi fi-rr-caret-up" />
              <span v-else class="fi fi-rr-caret-down" />
            </em>
          </div>
          <div class="title">
            <span v-if="!administration">
              <em><span class="fi fi-rr-caret-down" /></em>
            </span>
            <span v-else>
              <em><span class="fi fi-rr-caret-up" /></em>
            </span>
            <span>{{ $t('leftPanel.title.administration') }}</span>
          </div>
        </a>
      </div>
      <!--elemento del desplegable 2-->
      <div v-if="administration">
        <div
          v-if="false"
          class="item submenu-item"
          :title="$t('leftPanel.title.contacts')"
          :class="{ 'selected-menu-item': selectedItem === 'contacts' }"
        >
          <a href="#" @click="goContacts()">
            <div class="icon">
              <em><span class="fi fi-rr-address-book" /></em>
            </div>
            <div class="title">
              <span>{{ $t('leftPanel.title.contacts') }}</span>
            </div>
          </a>
        </div>
        <!--elemento del desplegable 2-->
        <div
          v-if="user.contact.tools.MY_COMPANY.VIEW"
          class="item submenu-item"
          :title="$t('leftPanel.title.my_company')"
          :class="{ 'selected-menu-item': selectedItem === 'myCompany' }"
        >
          <a href="#" @click="goCompany()">
            <div class="icon">
              <em><span class="fi fi-rr-building" /></em>
            </div>
            <div class="title">
              <span>{{ $t('leftPanel.title.my_company') }}</span>
            </div>
          </a>
        </div>
        <!--elemento del desplegable 2-->
        <div
          v-if="user.contact.tools.COMPANIES.VIEW"
          class="item submenu-item"
          :title="$t('leftPanel.title.company_list')"
          :class="{ 'selected-menu-item': selectedItem === 'companies' }"
        >
          <a href="#" @click="goCompanyList()">
            <div class="icon">
              <em><span class="fi fi-rr-building" /></em>
            </div>
            <div class="title">
              <span>{{ $t('leftPanel.title.company_list') }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Leftpanel',
  components: {},
  data() {
    return {
      gestion: false,
      administration: false,
      arrowdown: true,
      selectedItem: 'monitoring',
      isMenuCollapsed: true,
    };
  },
  computed: {
    ...mapState('authentication', ['user']),
  },

  mounted() {
    document.body.addEventListener('click', this.handleBodyClick);
  },

  methods: {
    handleBodyClick(event) {
      const panel = this.$refs.sidemenu;
      if (panel && !panel.contains(event.target)) {
        if (this.isMenuCollapsed === false) {
          this.menuCollapse();
        }
      }
    },
    menuCollapse() {
      const menu = document.querySelector('#sidemenu');
      menu.classList.toggle('menu-expanded');
      menu.classList.toggle('menu-collapsed');
      this.arrowdown = !this.arrowdown;
      this.isMenuCollapsed = !this.isMenuCollapsed;
    },
    goManagement() {
      this.gestion = !this.gestion;
    },
    goAdministration() {
      this.administration = !this.administration;
    },
    goHome() {
      this.selectedItem = 'monitoring';
      this.$f7.views.main.router.navigate('/home/', { reloadCurrent: true });
    },
    goOrder() {
      this.selectedItem = 'order';
      this.$f7.views.main.router.navigate('/orderPage/', {
        reloadCurrent: true,
      });
    },
    goShipment() {
      this.selectedItem = 'shipment';
      this.$f7.views.main.router.navigate('/shipmentPage/', {
        reloadCurrent: true,
      });
    },
    goNotifications() {
      this.selectedItem = 'notifications';
      this.$f7.views.main.router.navigate('/notifications/', {
        reloadCurrent: true,
      });
    },
    goClaims() {
      this.selectedItem = 'claims';
      this.$f7.views.main.router.navigate('/claimPage', {
        reloadCurrent: true,
      });
    },
    goPowerBi() {
      this.selectedItem = 'powerBi';
      this.$f7.views.main.router.navigate('/powerBiEmbedPage', {
        reloadCurrent: true,
      });
    },
    goGeneral() {
      this.$f7.views.main.router.navigate('/administration/carriers', {
        reloadCurrent: true,
      });
    },
    goConfigurationColumns() {
      this.$f7.views.main.router.navigate('/administration/columnsDevices', {
        reloadCurrent: true,
      });
    },
    goContacts() {
      this.selectedItem = 'contacts';
      this.$f7.views.main.router.navigate('/contactPage', {
        reloadCurrent: true,
      });
    },
    goCompany() {
      this.selectedItem = 'myCompany';
      this.$f7.views.main.router.navigate(`/CRUDCompany/${this.user.contact.company_id}/edit`, {
        reloadCurrent: true,
      });
    },
    goCompanyList() {
      this.selectedItem = 'companies';
        this.$f7.views.main.router.navigate('/companyPage', {
          reloadCurrent: true,
        });
    },
    goEntities() {
      this.selectedItem = 'entities';
      this.$f7.views.main.router.navigate('/entityPage', {
        reloadCurrent: true,
      });
    },
    goProtocols() {
      this.selectedItem = 'protocolsNew';
      this.$f7.views.main.router.navigate('/protocolPage', {
        reloadCurrent: true,
      });
    },
    goProtocolsAlerts() {
      this.$f7.views.main.router.navigate('/protocolsalerts/', {
        reloadCurrent: true,
      });
    },
    goProducts() {
      this.selectedItem = 'products';
      this.$f7.views.main.router.navigate('/productPage', {
        reloadCurrent: true,
      });
    },
    goDocuments() {
      this.selectedItem = 'documents';
      this.$f7.views.main.router.navigate('/documents/', {
        reloadCurrent: true,
      });
    },
    goDictionaries() {
      this.$f7.views.main.router.navigate('/dictionaries/', {
        reloadCurrent: true,
      });
    },
    goProfile() {
      this.$f7.views.main.router.navigate('/editProfile/', {
        reloadCurrent: true,
      });
    },
    goGroups() {
      this.selectedItem = 'groups';
      this.$f7.views.main.router.navigate('/groupPage', {
        reloadCurrent: true,
      });
    },
    goMonitoring() {
      this.selectedItem = 'monitoring';
      this.$f7.views.main.router.navigate('/monitoringPage', {
        reloadCurrent: true,
      });
    },
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.handleBodyClick);
  },
};
</script>

<style lang="scss" scoped>
.menu-expanded .submenu-item {
  padding-left: 20px;
}
</style>
