<template>
  <f7-app :style="appTheme" :params="f7params">
    <div class="leftpanel">
      <LeftPanel v-if="canAccessApp" />
    </div>
    <login v-if="loginNeeded && !requestNewPasssword" />
    <resetPassword v-if="recoveryPass" />
    <ResetPasswordConfirm v-if="getToken !== '' && getId !== ''" />
    <f7-view
      v-if="canAccessApp"
      main
      resizable
      :push-state="true"
      class="safe-areas"
    >
      <f7-popup
        class="demo-popup"
        :opened="showMessage"
        @popup:closed="closePopup"
      >
        <f7-page>
          <f7-navbar name="msgEmail" :title="$t('Login_msgEmail_title')">
            <f7-nav-right>
              <f7-link popup-close>
                {{ $t('Login_msgEmail_btnClose') }}
              </f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <p>{{ $t('Login_msgEmail_content') }}</p>
          </f7-block>
        </f7-page>
      </f7-popup>
    </f7-view>
  </f7-app>
</template>
<script>
import { Device } from 'framework7/framework7-lite.esm.bundle';
import { mapActions, mapState, mapGetters } from 'vuex';
import { loadMessages } from 'devextreme/localization';
import { APP_VERSION } from '../js/constants';
import cordovaApp from '../js/cordova-app';
import store from '../store/index';
import routes from '../js/routes';
import login from '../pages/accounts/loginPage.vue';
import resetPassword from '../pages/resetPassword/resetPassword.vue';
import ResetPasswordConfirm from '../pages/resetPassword/resetConfirm/resetConfirm.vue';
import dictionary from '../js/dxDictionary';
import LeftPanel from './LeftPanel.vue';

export default {
  name: 'App',
  components: {
    login,
    resetPassword,
    ResetPasswordConfirm,
    LeftPanel,
  },
  store,
  data() {
    return {
      version: APP_VERSION,
      showToDefault: true,
      // Framework7 Parameters
      f7params: {
        id: 'es.controlTower', // App bundle ID
        name: 'Control Tower', // App name
        theme: 'auto', // Automatic theme detection

        // App routes
        routes,
        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },

        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
          androidTextColor: 'white',
          androidBackgroundColor: '#5243AA',
          iosTextColor: 'white',
          iosBackgroundColor: '#5243AA',
        },
      },
      // Login screen data
      username: '',
      password: '',

      // Recovery password
      token: '',
      id: '',
    };
  },
  computed: {
    appTheme() {
      if (this.loggedUser !== null && this.loggedUser.settings !== null) {
        return {
          '--f7-theme-color': this.getThemePrimaryColor,
          // '--f7-navbar-bg-color': this.getThemePrimaryColor,
          '--f7-theme-color-rgb': this.getThemePrimaryColor,
          '--color-primary': this.getThemePrimaryColor,
          '--color-primary-light': this.getThemeTertiaryColor,
          '--color-primary-shade': this.getThemePrimaryColor,
          '--color-primary-accent': this.getThemeSecondaryColor,
          '--f7-theme-color-tint': this.getThemeTertiaryColor,
          '--f7-theme-color-gray': this.getThemeSecondaryColor,
          '--f7-theme-color-shade': this.getThemeSecondaryColor,
          '--f7-button-fill-bg-color': this.getThemePrimaryColor,
          '--hi-theme-primary': this.getThemePrimaryColor,
          '--hi-theme-secondary': this.getThemeSecondaryColor,
          '--hi-theme-tertiary': this.getThemeTertiaryColor,
          '--hi-theme-tertiary-rgb': this.hexToRgb(this.getThemePrimaryColor),
        };
      }
      return {
        '--f7-theme-color': 'var(--hi-color-primary)',
        '--color-primary': 'var(--hi-color-primary)',
        '--color-primary-shade': 'var(--hi-color-secondary)',
        '--f7-theme-color-shade': 'var(--hi-color-secondary)',
        '--color-primary-accent': 'var(--hi-color-secondary)',
      };
    },
    loginNeeded() {
      return this.loggedUser == null;
    },
    canAccessApp() {
      return this.loggedUser != null;
    },
    requestNewPasssword() {
      return this.loggedUser == null && this.WANT_TO_RECOVERY_PASS === false;
    },
    showMessage() {
      return this.messageChangePassword;
    },
    recoveryPass() {
      return this.wantToRecoveryPass;
    },
    getToken() {
      let token = '';
      const uri = window.location.href.split('?');
      if (uri.length === 2) {
        const vars = uri[1].split('&');
        const getVars = {};
        let tmp = '';
        vars.forEach((v) => {
          tmp = v.split('=');
          if (tmp.length === 2) {
            getVars[tmp[0]] = tmp[1];
          }
        });
        // do
        token = getVars.token;
      }
      return token;
    },
    getId() {
      let id = '';
      const uri = window.location.href.split('?');
      if (uri.length === 2) {
        const vars = uri[1].split('&');
        const getVars = {};
        let tmp = '';
        vars.forEach((v) => {
          tmp = v.split('=');
          if (tmp.length === 2) {
            getVars[tmp[0]] = tmp[1];
          }
        });
        // do
        id = getVars.id;
      }
      return id;
    },
    ...mapState('authentication', [
      'activationCode',
      'loggedUser',
      'appConfig',
      'wantToRemember',
      'WANT_TO_RECOVERY_PASS',
      'wantToRecoveryPass',
      'SET_PASSWORD',
      'messageChangePassword',
      'getPrimaryColor',
      'getSecondaryColor',
      'getTertiaryColor',
    ]),
    ...mapGetters('authentication', [
      'getThemePrimaryColor',
      'getThemeSecondaryColor',
      'getThemeTertiaryColor',
      'getUserLang',
    ]),
  },
  created() {
    loadMessages(dictionary);
  },
  mounted() {
    this.$f7ready((f7) => {
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
      }
    });
  },
  methods: {
    closePopup() {
      this.setMessageChangePassword();
    },
    ...mapActions('authentication', [
      'activateApp',
      'deactivateApp',
      'logout',
      'setMessageChangePassword',
    ]),
    /* Función que convierte colores hexadecimales a rgb */
    hexToRgb(color) {
      if (color.length === 7) {
        const r = parseInt(color.substr(1, 2), 16);
        const g = parseInt(color.substr(3, 2), 16);
        const b = parseInt(color.substr(5, 2), 16);
        return `${r},${g},${b}`;
      }
    },
  },
};
</script>
