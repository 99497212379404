import EventBus from '../js/event-bus';

export default {
  fetchRequest(url, payload, verb, enabledCache,
    headers, resolve, reject) {
    fetch(url, {
      method: verb,
      cache: enabledCache ? 'default' : 'no-cache',
      body: payload.data,
      headers,
    })
      .then((response) => {
        response.text()
          .then((text) => {
            if (response.status >= 300) {
              if (response.status === 401) {
                EventBus.$emit('invalidTokenDetected');
              }
              if (response.status === 409) {
                resolve({
                  response: text,
                });
              }
              reject(new Error(text));
            }
            resolve({
              response: text,
            });
          })
          .catch((error) => {
            reject(new Error(error));
          });
      })
      .catch((error) => {
        reject(new Error(error));
      });
  },

  async(url, payload, verb, contentType,
    { headerAuth, headerCompany },
    enabledCache = false) {
    return new Promise(((resolve, reject) => {
      const headers = {
        'Content-Type': contentType,
      };
      if (typeof headerAuth !== 'undefined' && headerAuth !== null) {
        headers.Authorization = headerAuth;
      }
      if (typeof headerCompany !== 'undefined' && headerCompany !== null) {
        headers.company = headerCompany;
      }
      this.fetchRequest(url, payload, verb, enabledCache,
        headers, resolve, reject);
    }));
  },

  async blobRequest(url, callback,
    headerAuth = null,
    headerCompany = null) {
    const headers = {
      Authorization: headerAuth,
      company: headerCompany,
    };
    const logoRes = await fetch(url, { headers });
    const blob = await logoRes.blob();
    const reader = new FileReader();
    reader.onloadend = () => callback(reader.result);
    reader.readAsDataURL(blob);
  },

  async blobRequestWithCallback(
    url,
    { headerAuth, headerCompany },
  ) {
    const self = this;
    return new Promise(((resolve, reject) => {
      self.blobRequest(url, (xhr, status) => {
        if (status >= 300 || status === 'timeout') {
          if (status === 401) {
            EventBus.$emit('invalidTokenDetected');
          }
          if (status === 409) {
            resolve(xhr);
          }
          reject(new Error(`${xhr.response}`));
        }
        try {
          resolve(xhr);
        } catch (e) {
          reject(e);
        }
      }, headerAuth, headerCompany);
    }));
  },
};
