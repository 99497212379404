import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Modules
import authentication from './modules/authentication';
import Gis from './modules/Gis';
import DatabaseSync from './modules/database/sync';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    authentication,
    DatabaseSync,
    Gis,
  },
  plugins: [
    new VuexPersistence({
      storage: window.localStorage,
      modules: ['authentication'],
    }).plugin,
  ],
});

store.dispatch('authentication/initialize');

export default store;
