export default {
  havePermission(permission) {
    if (typeof permission !== 'undefined') {
      return permission.value;
    }
    return false;
  },
  getProperty(properties, key) {
    for (const property of properties) {
      if (property.key === key) {
        return property.value;
      }
    }
    return '';
  },
  getFilteredErrorMessage(response) {
    const errorMap = {
      'company not found': 'company_not_found',
      'task type not found': 'task_type_not_found',
      'planner not found': 'planner_not_found',
      'task not found': 'task_not_found',
      'item not found': 'item_not_found',
      'farm not found': 'farm_not_found',
      'product not found': 'product_not_found',
      'product not found in prescription': 'product_not_in_prescription',
      'product not found in task': 'product_not_in_task',
      'product stock is less': 'product_stock_is_less',
      'prescription not found': 'prescription_not_found',
      'badget not found': 'badget_not_found',
      'badget version not found': 'badget_version_not_found',
      'badget version task not found': 'badget_version_task_not_found',
      'variety not found': 'variety_not_found',
      'irrigation task not found': 'irrigation_task_not_found',
      'scouting not found': 'scouting_not_found',
      'scouting point not found': 'scouting_point_not_found',
      'warehouse log not found': 'warehouse_log_not_found',
      'warehouse not found': 'warehouse_not_found',
      'cost center not found': 'cost_center_not_found',
      'badget versio is closed': 'badget_version_is_closed',
      'planner not in planning status': 'planner_not_in_planning_status',
      'task location exist with same date':
        'task_location_exist_with_same_date',
      'task not referenced': 'task_not_referenced',
      'planner not referenced': 'planner_not_referenced',
      'task deleted correctly': 'task_deleted_correctly',
      'no task for selected date': 'no_task_for_selected_date_404',
      'location not found': 'location_not_found',
      'worker not found': 'worker_not_found',
      'worker already exist in task': 'worker_already_exist_in_task',
      'worker already exist in other task':
        'worker_already_exist_in_other_task',
      'worker not exist in task': 'worker_not_exist_in_task',
      'code not exist in request': 'code_not_exist_in_request',
      'code not exist': 'code_not_exist',
      outdated: 'outdated',
      'invalid responsible': 'invalid_responsible',
      'invalid item family': 'invalid_item_family',
      'Not Found': 'Not_Found',
      'ID Must be specified': 'ID_Must_be_especified',
      'Phenological phase not found': 'Phenological_phase_not_found',
      'Plagues and diseases not found': 'Plagues_and_diseases_not_found',
      'Scouting_point required': 'Scouting_point_required',
      'incorrect authentication': 'error_401',
      TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED:
        'TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED',
      'You do not have permission': 'error_403',
      TASK_WORDER_MACHINERY_PERFORMANCE_INCOMPLETE:
        'TASK_WORDER_MACHINERY_PERFORMANCE_INCOMPLETE',
      TASK_WORDER_PRODUCT_PERFORMANCE_TOTAL_REAL_PRESCRIPTION_ZERO:
        'TASK_WORDER_PRODUCT_PERFORMANCE_TOTAL_REAL_PRESCRIPTION_ZERO',
      TASK_WORDER_PRODUCT_PERFORMANCE_QTYUSED_ZERO:
        'TASK_WORDER_PRODUCT_PERFORMANCE_QTYUSED_ZERO',
      'Token is invalid or expired': 'INVALID_SESSION',
    };

    const strResponse = String(response).toLowerCase();
    for (const [error, key] of Object.entries(errorMap)) {
      if (strResponse.includes(error)) {
        return key;
      }
    }

    // Si no se encuentra un mensaje de error específico, devuelve un valor predeterminado
    return `default_${strResponse}`;
  },
  /**
   * Returns true/false depending on if the number is a number and is an Int,
   * no decimals
   * @param {number} number
   * @returns boolean
   */
  isIntNumber(number) {
    return Number(number) === number && number % 1 === 0;
  },

  /**
   * Returns true/false depending on if the number is a number and has decimals
   * @param {number} number
   * @returns boolean
   */
  isFloatNumber(number) {
    return Number(number) === number && number % 1 !== 0;
  },

  /**
   * Format a number given a locale (by default de-DE)
   * and quantity of decimals (by default 2)
   *
   * @param {number} numberToFormat
   * @param {string} locale Optional, default = 'de-DE'
   * @param {number} decimals Optional, default = 2
   * @returns string | Error
   */
  formatNumberByLocale(numberToFormat, decimals = 2, locale = 'de-DE') {
    if (this.isIntNumber(numberToFormat)) {
      return Intl.NumberFormat(locale).format(numberToFormat);
    }

    if (this.isFloatNumber(numberToFormat)) {
      return Intl.NumberFormat(locale).format(
        Number(numberToFormat.toFixed(decimals))
      );
    }

    return new Error(
      'To format a number, we need a number or something that looks like a number'
    );
  },

  convert400ErrorToKey(error) {
    let msg = error;
    if (typeof error === 'object') {
      msg = error[0];
    }

    // Forced to string
    msg += '';

    if (msg.includes('may not be blank')) {
      msg = 'error_not_blank';
    } else if (msg.includes('A valid')) {
      msg = 'error_invalid_value';
    } else if (msg.includes('already exist')) {
      msg = 'error_already_exist';
    } else {
      msg = 'error_not_blank';
    }

    return msg;
  },
  /**
   * Para un array dado, añade un nuevo elemento, si este no está previamente contenido
   *
   * @param {array} elements Un array al que se le quiere añadir un nuevo elemento, no repetido
   * @param {*} newElement El nuevo elemento a añadir al array
   */
  addUniqueElementToArray(elements, newElement) {
    if (elements.indexOf(newElement) === -1) {
      elements.push(newElement);
    }
  },
  /**
   * Para un array de objetos dado, añade un nuevo elemento, si este no está previamente contenido
   *
   * @param {array} elements Un array al que se le quiere añadir un nuevo elemento, no repetido
   * @param {*} newElement El nuevo elemento a añadir al array
   * @param {string} key Es una string que indica la
   * clave que se usará para determinar si son iguales
   */
  addUniqueElementToArrayOfObjects(elements, newElement) {
    for (const element of elements) {
      const keysElement = Object.keys(element);
      let exists = true;
      for (const key of keysElement) {
        if (element[key] !== newElement[key]) {
          exists = false;
        }
      }
      if (exists) return;
    }
    elements.push(newElement);
  },
  setFilters(filters, newFilter, operator = '') {
    if (filters[1] === 'contains') {
      newFilter.filter_text = `${filters[2]}`;
    } else if (filters[1] === '=') {
      const filter = this.convertVarNameFrontToBack(filters[0]);
      if (filter !== '') {
        const newFilterFormated = {
          filter,
          value: `${operator}${filters[2]}`,
        };
        this.addUniqueElementToArrayOfObjects(
          newFilter.filters_columns,
          newFilterFormated,
          []
        );
      }
    }
  },
  setAllFilters(filters, newFilter, operator = '') {
    if (Array.isArray(filters[0])) {
      for (const subFilters of filters) {
        this.setAllFilters(subFilters, newFilter, operator);
      }
    } else if (filters[0] === '!') {
      this.setAllFilters(filters[1], newFilter, '!');
    } else {
      this.setFilters(filters, newFilter, operator);
    }
  },
  getFiltersFormated(filtersDevExtreme) {
    const newFilter = {
      filter_text: '',
      filters_columns: [],
    };
    if (
      typeof filtersDevExtreme === 'undefined' ||
      filtersDevExtreme === null
    ) {
      return newFilter;
    }
    for (const filters of filtersDevExtreme) {
      if (Array.isArray(filters[0])) {
        for (const subFilters of filters) {
          this.setAllFilters(subFilters, newFilter);
        }
      } else if (filters[0] === '!') {
        this.setAllFilters(filters[1], newFilter, '!');
      } else {
        this.setAllFilters(filtersDevExtreme, newFilter);
      }
    }
    return newFilter;
  },
  getSortsFormated(sortsDevExtreme) {
    const sorts = [];
    if (typeof sortsDevExtreme === 'undefined' || sortsDevExtreme === null) {
      return sorts;
    }
    for (const sort of sortsDevExtreme) {
      sorts.push({
        sort: this.convertVarNameFrontToBack(sort.selector, true),
        value: sort.desc ? 'desc' : 'asc',
      });
    }
    return sorts;
  },
  /**
   * Se adapta el formato que trabaja el back al formato utilizado en devextreme
   * para los valores posibles en los filtros
   *
   * @param {*} filtersGroup
   * @returns {object} Devuelve una lista de objetos
   * con el formato utilizado en devextreme para filtros
   */
  getFilterGroupTasksFormated(filtersGroup) {
    const filtersGroupFormated = [];
    for (const group of filtersGroup) {
      const newFilterGroup = {
        key: group,
        items: null,
        count: 1,
      };
      filtersGroupFormated.push(newFilterGroup);
    }
    return filtersGroupFormated;
  },
  /**
   * Determina si un array no está vacio
   *
   * @param {array} array Es el array a evaluar
   * @returns {boolean} Devuelve true si el array no está vacio
   */
  isNotEmptyArray(array) {
    return Array.isArray(array) && array.length > 0;
  },
  getError(error) {
    console.log(error);
    // Si el error es no controlado, no estará en formato json y por tanto,
    // se devolverá como vino para que se visualice en raw
    try {
      return JSON.parse(error.message).code;
    } catch (e) {
      return error;
    }
  },
  getErrorArray(error) {
    try {
      if (JSON.parse(error.message)?.errors.length) {
        return JSON.parse(error.message)?.errors[0]?.detail;
      }
      return JSON.parse(error.message).code;
    } catch (e) {
      return error;
    }
  },
  autoGenerateCode(currentCompany, abbreviatedElementName) {
    let initials = '';
    const numberOfWords = currentCompany.name.split(' ');
    for (const word of numberOfWords) {
      initials += word.substr(0, 1);
    }
    return `${initials}${abbreviatedElementName}${Date.now()}`;
  },
  /**
   * Devuelve un nombre de clase css según el estado de la tarea
   *
   * @param {string} status Enumerado que identifica el estado de la tarea
   */
  setClassByStatus(status) {
    let colorCell = '';
    switch (status) {
      case 'PENDING':
        colorCell = 'Pendin';
        break;
      case 'ON_ROUTE':
        colorCell = 'On_route';
        break;
      case 'DELIVERED':
        colorCell = 'Delivered';
        break;
      case 'ASSIGNED':
        colorCell = 'Assigned';
        break;
      case 'ACTIVATED':
        colorCell = 'Activate';
        break;
      case 'DEACTIVATED':
        colorCell = 'Desactivate';
        break;
      case 'COMPLETE':
        colorCell = 'Acepted';
        break;
      case 'INCOMPLETE':
        colorCell = 'Invalid';
        break;
      case 'CANCEL':
        colorCell = 'Cancel';
        break;
      case 35:
        colorCell = 'Cancel';
        break;
      case 'LOADING':
        colorCell = 'Pendin';
        break;
      case 'UNLOADING':
        colorCell = 'Pendin';
        break;
      default:
        break;
    }
    return colorCell;
  },
  /**
   * Permite obtener una traducción para un tag dado permitiendo nulos.
   *
   * @param {string} tag Tag usado como palabra clave a la hora de obtener la traducción en el diccionario i18n
   * @param {object} that Instancia del componente que invoca la función. Esto permite utilizar la librería i18n
   * @param {string} prefix Opcional, permite añadir un prefijo al tag para diccionarios con agrupaciones
   * @returns {string} Traducción para el tag dado
   */
  translateWithNull(tag, that, prefix = '') {
    if (typeof tag === 'undefined' || tag === null || tag === '') {
      return '';
    }
    return that.$t(`${prefix}${tag}`);
  },
  /**
   * Indica si el campo (obligatorio en un formulario) es válido: Definido y con valor
   * @param {*} field El campo a determinar si está bien definido
   * @returns {boolean} Devuelve True si el campor está definido y tiene valor (no vacio)
   */
  isFieldValid(field) {
    return !(typeof field === 'undefined' || field === null || field === '');
  },
  //funcion para limpiar campos vacios de un objeto
  /**
   * Elimina los campos vacios de un objeto
   * @param {object} obj El objeto a limpiar
   * @returns {object} Devuelve el objeto sin campos vacios
   */
  cleanObject(obj) {
    const newObj = {};
    Object.keys(obj).forEach((prop) => {
      if (obj[prop] !== null && obj[prop] !== undefined && obj[prop] !== '') {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  },
};
