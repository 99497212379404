import {
  APPLICATION_NAME,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
  DEFAULT_TERTIARY_COLOR,
  APP_LOGO,
} from '../../js/constants';
import EventBus from '../../js/event-bus';
import i18n from '../../js/i18n.config';
import api from '../../services/Api';

export default {
  namespaced: true,

  state: {
    powerBiAccessToken: '',

    appConfig: {
      name: APPLICATION_NAME,
      logoUrl: APP_LOGO,
      apiBaseUrl: null,
      primaryColor: DEFAULT_PRIMARY_COLOR,
      secondaryColor: DEFAULT_SECONDARY_COLOR,
      tertiaryColor: DEFAULT_TERTIARY_COLOR,
      accessKey: 'fadsf5DS5Ffd5s',
      defaultPanelLoad: 'Devices',
      theme: {
        primaryColor: '#2c2484',
        secondaryColor: '#048c9c',
        tertiaryColor: '#06a8b2',
        columnsPreference: [
          'Temp',
          'Range',
          'Location',
          'LastReport',
          'Client',
          'Device',
          'Carrier',
          'NumberTransport',
          'Activation',
          'Delivery',
          'Origin',
          'Cargo',
          'Destination',
          'GenericAttribute01',
          'GenericAttribute02',
          'GenericAttribute03',
          'GenericAttribute04',
          'GenericAttribute05',
        ],
        logo: 'hispatec-track-white.svg',
        modalEnableDevice: '',
        // 'Settings',
        nameAttributesGeneric: ['Origin Port', 'Arrival Port', 'ETD', 'ETA'],
        culture: null,
        mandatoryActivationFields: [
          'NumberDc',
          'dateActivation',
          'dateDeliver',
          'lowerTemperature',
          'higherTemperature',
          'typeTransport',
        ],
        defaultPanelLoad: 'Devices',
        themeColor: 'purple',
      },
      permissions: {
        menu_notifications: {
          name: 'menu_notifications',
          value: true,
          childs: null,
        },
        menu_protocols: {
          name: 'menu_protocols',
          value: true,
          childs: null,
        },
        menu_complaints: {
          name: 'menu_complaints',
          value: true,
          childs: null,
        },
        menu_reports: {
          name: 'menu_reports',
          value: true,
          childs: null,
        },
        menu_devices: {
          name: 'menu_devices',
          value: true,
          childs: null,
        },
        menu_trips: {
          name: 'menu_trips',
          value: true,
          childs: null,
        },
        menu_trips_create: {
          name: 'menu_trips_create',
          value: true,
          childs: null,
        },
        menu_analytics: {
          name: 'menu_analytics',
          value: true,
          childs: null,
        },
        menu_analytics_general: {
          name: 'menu_analytics_general',
          value: true,
          childs: null,
        },
        menu_analytics_initial: {
          name: 'menu_analytics_initial',
          value: true,
          childs: null,
        },
        menu_administration: {
          name: 'menu_administration',
          value: true,
          childs: null,
        },
        menu_administration_main: {
          name: 'menu_administration_main',
          value: true,
          childs: null,
        },
        menu_administration_chargingPoints: {
          name: 'menu_administration_chargingPoints',
          value: true,
          childs: null,
        },
        menu_administration_cargos: {
          name: 'menu_administration_cargos',
          value: true,
          childs: null,
        },
        menu_administration_carriers: {
          name: 'menu_administration_carriers',
          value: true,
          childs: null,
        },
        visibility_third_parties_show: {
          name: 'visibility_third_parties_show',
          value: true,
          childs: null,
        },
        documents_tab_show: {
          name: 'documents_tab_show',
          value: true,
          childs: null,
        },
        menu_administration_columns_devices: {
          name: 'menu_administration_columns_devices',
          value: true,
          childs: null,
        },
        photo_login_show: {
          name: 'photo_login_show',
          value: true,
          childs: null,
        },
      },
    },
    loggedUser: null,
    user: null,
    wantToRemember: false,
    wantToRecoveryPass: false,
    changePassword: false,
    messageChangePassword: false,
    currentLocation: null,
    actualMenuSelected: undefined,
    currentCompanyIdSelected: null,
  },

  getters: {
    getWantToRemember: (state) => state.wantToRemember,
    getPermissions: (state) => state.appConfig.permissions,
    getActualMenuSelected: (state) => state.actualMenuSelected,
    getLogo: (state) => state.appConfig.logoUrl,
    getPrimaryColor: (state) => state.appConfig.primaryColor,
    getSecondaryColor: (state) => state.appConfig.secondaryColor,
    getTertiaryColor: (state) => state.appConfig.tertiaryColor,
    getThemeColor: (state) => state.user?.settings.theme,
    getThemePrimaryColor: (state) => state.user?.settings.primaryColor,
    getThemeSecondaryColor: (state) => state.user?.settings.secondaryColor,
    getThemeTertiaryColor: (state) => state.user?.settings.tertiaryColor,
    getThemeData: (state) => state.appConfig.theme,
    getColumnPreferences: (state) => state.appConfig.theme.columnsPreference,
    getGenericAttributesNames: (state) =>
      state.appConfig.theme.nameAttributesGeneric,
    getAccessKey: (state) => state.appConfig.accessKey,
    getUserLang: (state) => state.user?.contact.settings.culture,
    getUsername: (state) => state.user?.username,
    getUserThemename: (state) => state.user?.contact.name,
    getTokenDateExpired: (state) => '2022-09-05T16:31:00.6595364Z',
    getDefaultPanelLoad: (state) => 'Devices',
    currentCompanyId: (state) => state.user?.contact.company_id,
    getGoogleMapsApiKey: (state) => state.user?.google_maps_api_key,
    getGoogleMapsHybridApiKey: (state) =>
      state.user?.google_maps_hybrid_api_key,
    companies: (state) => state.user.contact.collaborator_companies,
  },

  mutations: {
    setPowerBiAccessToken(state, newPowerBiAccessToken) {
      state.powerBiAccessToken = newPowerBiAccessToken;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_LOGGED_USER(state, payload) {
      state.loggedUser = payload;
    },
    SET_WANT_TO_REMEMBER(state) {
      state.wantToRemember = !state.wantToRemember;
    },
    WANT_TO_RECOVERY_PASS(state) {
      state.wantToRecoveryPass = !state.wantToRecoveryPass;
    },
    CHANGE_PASSWORD(state) {
      state.changePassword = !state.changePassword;
    },
    SET_MESSAGE_CHANGE_PASSWORD(state) {
      state.messageChangePassword = !state.messageChangePassword;
    },
    setActualMenuSelected(state, payload) {
      state.actualMenuSelected = payload;
    },
    SET_APPCONFIG(state, payload) {
      if (payload.user && payload.user.settings) {
        if (payload.logo !== '') {
          state.appConfig.logoUrl = `../static/img/customers/${state.appConfig.theme.logo}`;
        }
        if (payload.primaryColor !== '') {
          state.appConfig.primaryColor = payload.user.settings.primaryColor;
        }
        if (payload.secondaryColor !== '') {
          state.appConfig.secondaryColor = payload.user.settings.secondaryColor;
        }
        if (payload.tertiaryColor !== '') {
          state.appConfig.tertiaryColor = payload.user.settings.tertiaryColor;
        }
        if (payload.accessKey !== '') {
          state.appConfig.accessKey = payload.accessKey;
        }
        if (payload.defaultPanelLoad !== '') {
          state.appConfig.defaultPanelLoad = 'Devices';
        }
      }
    },
    setWantToRecoveryPass(state, value) {
      state.wantToRecoveryPass = value;
    },
    setCurrentCompanyIdSelected(state, value) {
      state.currentCompanyIdSelected = value;
    },
  },

  actions: {
    setPowerBiAccessToken({ commit }, newPowerBiAccessToken) {
      commit('setPowerBiAccessToken', newPowerBiAccessToken);
    },
    initialize(context) {
      if (context.state.loggedUser) {
        EventBus.$emit('newLoggedUser', {
          payload: context.state.loggedUser,
          companyId: context.state.user?.contact.company_id,
        });
      }
      EventBus.$on('invalidTokenDetected', () => context.dispatch('logout'));
      EventBus.$on('newLoggedUser', () =>
        context.commit('SET_APPCONFIG', context.state)
      );
      EventBus.$on('newLoggedUser', ({ payload }) =>
        context.commit('SET_LOGGED_USER', payload)
      );
      EventBus.$on('newLoggedUser', ({ payload }) =>
        context.commit('SET_USER', JSON.parse(payload))
      );
    },
    async login({ commit }, payload) {
      const { username, password, remember } = payload;
      await api.login({
        username,
        password,
        remember,
      });
    },
    async logout(context) {
      context.commit('SET_LOGGED_USER', null);
      context.commit('SET_WANT_TO_REMEMBER');
      context.commit('setActualMenuSelected', undefined);
      history.pushState({}, '', '/');
    },
    selectLocation({ commit }, value) {
      commit('setCurrentLocation', value);
    },
    wantToRecoveryPass({ commit }) {
      commit('WANT_TO_RECOVERY_PASS');
    },
    changePassword({ commit }) {
      commit('CHANGE_PASSWORD');
    },
    setCurrentCompanyIdSelected({ commit }, value) {
      commit('setCurrentCompanyIdSelected', value);
    },
    setWantToRemember({ commit }) {
      commit('SET_WANT_TO_REMEMBER');
    },
    setMessageChangePassword({ commit }) {
      commit('SET_MESSAGE_CHANGE_PASSWORD');
    },
    setActualMenuSelected({ commit }, payload) {
      commit('setActualMenuSelected', payload);
    },
    setWantToRecoveryPass(context, value) {
      context.commit('setWantToRecoveryPass', value);
    },
  },
};
