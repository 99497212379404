<template>
  <div class="main-login">
    <f7-block>
      <f7-login-screen-title class="no-margin-bottom" name="txtWelcome">
        <img class="login-logo" :src="APP_LOGO" :alt="APPLICATION_NAME" />
        <h4 class="text-color-white no-padding-bottom no-margin-bottom">
          Bienvenido 👋,
        </h4>
      </f7-login-screen-title>
      <p class="text-color-white text-align-center">
        Introduzca su nueva contraseña.
      </p>
      <div class="card-login margin-bottom">
        <div class="card-body">
          <f7-list form no-hairlines-between>
            <f7-list-input
              name="inpEmail"
              outline
              item-label
              type="text"
              :placeholder="$t('login.inpUsername.placeholder')"
              clear-button
              label="Email"
              max="255"
              required
              validate
              :error-message="$t('login.inpUsername.validate')"
              autocomplete="email"
              @input="email = $event.target.value"
            />
            <f7-list-input
              name="inpPassword"
              outline
              item-label
              type="password"
              :placeholder="$t('login.inpUsername.placeholder')"
              clear-button
              label="Contraseña"
              max="255"
              required
              validate
              :error-message="$t('login.inpUsername.validate')"
              autocomplete="current-password"
              @input="password = $event.target.value"
            />
            <f7-list-input
              name="inpConfirmPassword"
              outline
              item-label
              label="Confirmar Contraseña"
              type="password"
              :placeholder="$t('login.inpPassword.placeholder')"
              clear-button
              max="255"
              required
              autocomplete="current-password"
              validate
              :error-message="$t('login.inpPassword.validate')"
              @input="confirmPassword = $event.target.value"
              @keypress.native.enter="signIn"
            />
          </f7-list>
          <f7-list>
            <f7-button
              name="btnSend"
              preloader
              button
              button-large
              fill
              no-ripple
              style="color: #000000"
              @click="signIn"
            >
              {{ $t('login.btnSend') }}
            </f7-button>
          </f7-list>
        </div>
      </div>
      <f7-block-footer class="margin-top">
        <a
          class="button link external text-color-yellow padding-vertical-half"
          target="_parent"
          href="http://localhost:8080/"
          rel="noopener"
          @click="irLogin"
          ><u class="text-color-white"> {{ $t('login.txtGoToLogin') }}</u></a
        >
      </f7-block-footer>
      <f7-block-footer class="margin-top">
        <span class="text-color-white">{{ $t('login.txtLoginInfo') }}</span>
        <a
          class="link external text-color-yellow padding-vertical-half"
          target="_blank"
          href="https://www.hispatectrack.com/"
          rel="noopener"
          ><u>{{ $t('login.txtRequestRegistration') }}</u></a
        >
      </f7-block-footer>
      <f7-block class="text-align-center content-login-footer">
        <span class="text-color-white"> &#xA9; HispatecTrack</span>
        <span class="text-color-white"
          ><em class="fa fa-phone"></em> <span class="small">+34</span>
          <a href="tel:+34662 92 67 32" class="link external text-color-white"
            >662 92 67 32</a
          >
        </span>
        <span>
          <a
            class="link external text-color-white small"
            target="_blank"
            href="https://www.hispatectrack.com/aviso-legal/"
            rel="noopener"
            >{{ $t('login.txtLegalWarning') }}</a
          >
        </span>
        <span>
          <a
            class="link external text-color-white small"
            target="_blank"
            rel="noopener"
            href="https://www.hispatectrack.com/politica-de-privacidad/"
            >{{ $t('login.txtPrivacy') }}</a
          >
        </span>
      </f7-block>
    </f7-block>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { APPLICATION_NAME, APP_LOGO } from '../../../js/constants';
import Api from '../../../services/Api';

export default {
  name: 'ResetConfirm',
  props: {
    token: { type: String, default: '' },
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      email: '',
      url: window.location.origin,
      APPLICATION_NAME,
      APP_LOGO,
    };
  },
  computed: {
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    ...mapState('authentication', ['WANT_TO_RECOVERY_PASS']),
  },
  beforeMount() {
    this.resetLastSync();
  },
  methods: {
    async signIn() {
      this.$f7.preloader.show();
      try {
        if (this.email !== '' && this.email !== 'undefined') {
          if (this.password !== '' && this.password !== 'undefined') {
            if (this.password === this.confirmPassword) {
              await Api.resetPassword.resetConfirm({
                password: this.password,
                token: this.token,
                email: this.email,
              });
              this.$f7.dialog.alert(this.$t('recovery.passwordReset'));
            } else {
              this.$f7.dialog.alert(this.$t('recovery.differentPasswords'));
            }
          } else {
            this.$f7.dialog.alert(this.$t('recovery.emptyPassword'));
          }
        } else {
          this.$f7.dialog.alert(this.$t('recovery.insertEmail'));
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t('recovery.errorRecovery'));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    irLogin() {
      this.setWantToRecoveryPass(false);
    },
    ...mapActions('authentication', [
      'login',
      'setWantToRemember',
      'changePassword',
      'wantToRecoveryPass',
      'setWantToRecoveryPass',
    ]),
    ...mapActions('DatabaseSync', ['resetLastSync']),
  },
};
</script>
